import { graphql } from 'gatsby'
import React from 'react'
import Content from '../components/Content'
import Copy from '../components/Copy'
import Stage from '../components/Stage'
import Default from '../templates/default'
import { FluidObject } from 'gatsby-image'

export interface UeberUnsProps {
    data: {
        stageImage: {
            childImageSharp: {
                fluid: FluidObject
            }
        }
    }
}

const UeberUns: React.FC<UeberUnsProps> = ({ data }: UeberUnsProps): JSX.Element => (
    <Default>
        <Content>
            <Stage image={data.stageImage.childImageSharp} small />

            <Copy>
                2018 wurde Bride Surprise von Jan Sagemüller und den Inhabern von Fawntastique, Matthias Blank und
                Ann-Cathrin Krauß gegründet. Gemeinsam haben sie sich zum Ziel gesetzt, angehenden Trauzeuginnen unter
                die Arme zu greifen und besondere Kurse in und um Köln für einen perfekten JGA zusammenzustellen.
            </Copy>
            <Copy>
                „Die Besonderheit an Bride Surprise“, so Ann-Cathrin Krauß, „ist das wir nicht nur Kurse und
                ausgefallene Angebote rund um das Thema JGA anbieten. Wir nehmen die angehenden Trauzeuginnen an die
                Hand und unterstützen sie mit professionellen Planern aus der Event- und Hochzeitsbranche bei der
                Umsetzung ihres ganz individuellen Bachelorette Party.“ Das Angebot wird stetig geprüft, aktualisiert
                und ausgebaut, so dass den Gruppen immer wieder ein neues Highlight auf der Bride Surprise Website
                geboten werden kann.
            </Copy>
            <Copy>
                Doch wieso Bride Surprise und woher kam die Idee? „Bride Surprise bedeutet übersetzt - überraschte Braut
                - und genau darum geht es bei einem Junggesellinnenabschied doch auch, oder?“, so Matthias Blank. Bei
                einer Brainstorming-Runde warf er den Namen in den Raum, welche alle drei sofort auf Anhieb begeisterte
                und zu der jetzigen Website inspirierte. Die Idee, sich mit einer solchen Thematik zu beschäftigen und
                eine Seite zu konzipieren, welchen Trauzeuginnen und den Freundinnen der Braut unterstützen soll, kam
                bei einem Frühstück an einem Sonntag. „Matthias und Ann-Cathrin haben durch Fawntastique und die
                angebotenen Flower-Crown Workshops, die Möglichkeit gehabt, mit vielen Trauzeuginnen zu reden und heraus
                kam immer wieder das gleiche positive Feedback auf den Support und die Vorschläge für die
                verschiedensten Kurse und Events zum Thema JGA in Köln feiern.“, so Jan Sagemüller. Die Idee war geboren
                und ein halbes Jahr später, können die drei Kölner diese einzigartige Plattform präsentieren. Eine
                Plattform für Euren individuellen Junggesellinnenabschied.
            </Copy>
        </Content>
    </Default>
)

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "stage/ueber-uns.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 250, quality: 75, cropFocus: CENTER) {
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                }
            }
        }
    }
`

export default UeberUns
